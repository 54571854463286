<template>
  <div class="profile-head">
    <b-avatar
      v-if="previewImage == null"
      id="avatar-profile"
      size="130px"
      rounded="sm"
      variant="primary"
    />
    <b-avatar
      v-else
      id="avatar-profile"
      size="130px"
      rounded="sm"
      variant="primary"
      :src="previewImage"
    />
    <input
      :ref="`change-avatar-${componentId}`"
      type="file"
      class="d-none"
      accept="image/jpeg, image/png, image/gif"
      @input="onInputAvatarImage($event)"
    >
    <b-button
      :id="`avatar-upload-${componentId}`"
      class="btn-camera"
      variant="secondary"
      :disabled="loading"
      @click="$refs[`change-avatar-${componentId}`].click()"
    >
      <feather-icon
        icon="CameraIcon"
        size="20"
      />
    </b-button>
    <b-button
      :id="`avatar-remove-${componentId}`"
      class="btn-camera"
      variant="danger"
      :disabled="loading"
      @click="actionRemoveSubmitModal()"
    >
      <feather-icon
        icon="XIcon"
        size="20"
      />
    </b-button>
    <b-tooltip
      title="Cargar imagen de perfil"
      class="cursor-gender"
      :target="`avatar-upload-${componentId}`"
    />
    <b-tooltip
      title="Eliminar imagen de perfil"
      class="cursor-gender"
      :target="`avatar-remove-${componentId}`"
    />
  </div>
</template>

<script>
import {
  BButton, BAvatar, BOverlay, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BAvatar,
    BOverlay,
    BTooltip,
  },
  props: {
    name: {
      default: '',
    },
    avatar: {
      default: null,
    },
    loading: {
      default: false,
    },
  },
  data() {
    return {
      componentId: this.$getId('avatar-profile'),
      default_image: require('@/assets/images/avatars/default-image.png'),
      previewImage: null,
      image: null,
    }
  },
  watch: {
    avatar: {
      deep: true,
      handler(val) {
        this.previewImage = val
      },
    },
  },
  created() {},
  methods: {
    // Manejo de Imagen de Avatar
    onRemoveAvatarImage() {
      this.previewImage = null
    },
    onInputAvatarImage(event) {
      try {
        if (event == null) {
          this.previewImage = null
          this.image = null
          return
        }

        this.image = event.target.files[0]
        const reader = new FileReader()
        reader.onload = event => {
          this.previewImage = reader.result
          setTimeout(() => {
            this.actionSubmitModal()
          }, 500)
        }
        reader.readAsDataURL(this.image)
      } catch (error) {
        console.log(error)
      }
    },
    actionSubmitModal() {
      /*
      this.$bvModal
        .msgBoxConfirm('Please, confirm that you want to remove this item. Note that the action is irreversible.', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes, change my picture',
          cancelTitle: 'Cancel',
          centered: true,
        })
        .catch(err => {
          this.$handleErrorValidation(err)
        })
        .then(value => {
          if (value) {
            this.$emit('input', this.image)
          }
        })
        */
    },
    actionRemoveSubmitModal() {
      this.$bvModal
        .msgBoxConfirm('Please, confirm that you want to remove this item. Note that the action is irreversible.', {
          title: 'Confirmation',
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Yes, remove my picture',
          cancelTitle: 'Cancel',
          centered: true,
        })
        .catch(err => {
          this.$handleErrorValidation(err)
        })
        .then(value => {
          if (value) {
            this.$emit('input', null)
          }
        })
    },
  },
}
</script>

<style lang="scss">
.profile-head {
    margin-bottom: 3rem;
    width: 130px;
    position: absolute;
    #avatar-profile {
        .avatar-inner {
            border-radius: 0 0 1.5rem 1.5rem;
            .avatar-item {
                background-color: #2a1a4652;
                img {
                    min-height: 35vh;
                    object-fit: cover;
                    background-color: #000000;
                    mix-blend-mode: soft-light;
                    max-height: 30rem;
                }
            }
            .avatar-caption {
                left: 1rem !important;
                bottom: 0rem;
                text-align: start !important;
                h3 {
                    color: #ffffff;
                    text-shadow: 0 0px 4px black;
                }
            }
        }
        .avatar-indicators {
            top: 0;
            width: 100%;
            margin: 0;
            z-index: 1;
            li {
                background-color: var(--secondary) !important;
                width: 100%;
                margin: 0 1rem;
            }
        }
    }
    .btn {
        color: var(--primary) !important;
        position: relative;
        z-index: 1;
        margin-right: 1rem;
        margin-top: 1rem;
        border-radius: 100%;
        padding: 1rem;
        left: 10px;
        top: -40px;
    }
}
</style>
